import '../AreasOfFocus/areas-of-focus.scss'
import React, { useState } from 'react'

const AreasOfFocus = ({ provider }) => {
  const pronoun = provider && provider.gender ? (provider.gender.toLowerCase() === 'male' ? 'his' : 'her') : 'the'
  const [showAll, setShowAll] = useState(false);
  const shouldDisplayShowMore = provider.areaOfFocus.length > 5;

  const handleToggle = () => {
    setShowAll(!showAll);
  }
  

  return (
    provider.areaOfFocus && provider.areaOfFocus.length > 0
      ? <div className=''>

        <h4 className='provider-aof-header'>Areas of Focus</h4>
        <div className='provider-aof-list'>
          <ul>
            {provider.areaOfFocus.slice(0, showAll ? provider.areaOfFocus.length : 5).map((element, index) => (<li key={index}>{element}</li>))}
          </ul>
          <p>This may not be a complete list for Dr. {provider.lastName}. Please call {pronoun} office if you have questions.</p>
        </div>
         {shouldDisplayShowMore && (
          <div className="show-all-aof">
          <a className='clickable' onClick={handleToggle}>{showAll ? 'Show Less Areas of Focus' : 'Show All Areas of Focus'}</a>
         </div>
        )}
        <div className='flex justify-center'>
          <div className='drawer-divider' />
        </div>
      </div> : null
  )
}

export default AreasOfFocus
