import { connect } from 'react-redux'
import NoResultsFound from './NoResultsFound.jsx'
import { getLookingFor } from '../../../shared/utility-functions/searchParams.js'

const mapStateToProps = (state, ownProps) => ({
  lookingFor: getLookingForValue(state),
  flow: ownProps.flow,
  fadSearchedBounds: (state.fadMap && state.fadMap.searchedBounds),
  isSuperResults: ownProps.isSuperResults ? true : false
})

function getLookingForValue (state) {
  if (getLookingFor()) {
    return getLookingFor()
  } else {
    return (state.searchInput.searchQuery ? state.searchInput.searchQuery : state.searchInput.searchLocation.address)
  }
}

export default connect(mapStateToProps)(NoResultsFound)
